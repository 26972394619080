import { useLocation } from "@reach/router";
import { Spin } from "antd";
import { Link } from "gatsby";
import React from "react";
import { connect } from "react-redux";
import { documentImg, news2Img, video2Img } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// const PAGESIZE = 10;
// markup
const NewsAndVideo = (props) => {
  const location = useLocation();
  const { t } = useTranslation();

  //   useEffect(() => {
  //     getData((page - 1) * PAGESIZE);
  //   }, [page]);

  //   useEffect(() => {
  //     // console.log("change type useEffect");
  //     getData((1 - 1) * PAGESIZE);
  //   }, [languageType]);

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={false}>
          <NavHeader
            showBack={true}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* 资讯  */}
                  {t("info", sourceKey.newAndVideo)}
                </div>
              </React.Fragment>
            }
          >
            <div className="pt-10">
              <div className="grid grid-cols-2 gap-2">
                <Link
                  to={routes.news.to()}
                  state={{ prevPath: location.pathname }}
                  className="col-span-2  "
                >
                  <div className="m-5 py-2 rounded-2xl  touchbox-bg-color">
                    <div className="justify-start flex my-2 pl-8">
                      <div>
                        <img src={news2Img} style={{ width: 45, height: 45 }} />
                      </div>
                      <div
                        className="ml-8  mt-1 text-white font-semibold text-center"
                        style={{
                          fontSize: "25px",
                        }}
                      >
                        {t("news", sourceKey.newAndVideo)}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={routes.videos.to()}
                  state={{ prevPath: location.pathname }}
                  className="col-span-2  "
                >
                  <div className="m-5 py-2 rounded-2xl  touchbox-bg-color">
                    <div className="justify-start flex my-2 pl-8">
                      <div>
                        <img
                          src={video2Img}
                          style={{ width: 45, height: 45 }}
                        />
                      </div>
                      <div
                        className="ml-8  mt-1 text-white font-semibold text-center"
                        style={{
                          fontSize: "25px",
                        }}
                      >
                        {t("video", sourceKey.newAndVideo)}
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to={routes.documents.to()}
                  state={{ prevPath: location.pathname }}
                  className="col-span-2  "
                >
                  <div className="m-5 py-2 rounded-2xl  touchbox-bg-color">
                    <div className="justify-start flex my-2 pl-8">
                      <div>
                        <img
                          src={documentImg}
                          style={{ width: 45, height: 45 }}
                        />
                      </div>
                      <div
                        className="ml-8  mt-1 text-white font-semibold text-center"
                        style={{
                          fontSize: "25px",
                        }}
                      >
                        {t("document", sourceKey.newAndVideo)}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            {/* <div>
              <iframe
                src="https://drive.google.com/file/d/1Zz8cMFfUb73T2ava25HGZk9Bw36mdYHQ/preview"
                height="280"
                allow="autoplay"
              ></iframe>
            </div> */}
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsAndVideo);
